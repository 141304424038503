import React from 'react';

import { secondsToHoursMinutesSeconds } from '@/utils/seconds-to-hours-minutes-seconds';

export const useLocationWaitTime = ({ locationType, hasWaitTime, epicDepartmentId }) => {
    const [waitTimeInSeconds, setWaitTimeInSeconds] = React.useState(null);

    React.useEffect(() => {
        if (locationType !== 'urgent-care' || !hasWaitTime || !epicDepartmentId) {
            return;
        }

        const fetchEpicWaitTime = async () => {
            try {
                const newWaitTime = await fetch(
                    `/bin/ahs/waitTimes?departmentId=${epicDepartmentId}`,
                )
                    .then((r) => r.json())
                    .then((r) => r.waitTime);

                const normalizedWaitTimeInSeconds = parseInt(newWaitTime, 10) / 60;

                if (isNaN(normalizedWaitTimeInSeconds)) {
                    return;
                }

                setWaitTimeInSeconds(normalizedWaitTimeInSeconds);
            } catch (e) {
                console.error(e.message);
            }
        };
        fetchEpicWaitTime();

        return () => {};
    }, [locationType, hasWaitTime, epicDepartmentId]);

    return [
        waitTimeInSeconds,
        waitTimeInSeconds != null
            ? secondsToHoursMinutesSeconds(waitTimeInSeconds, 'descriptive', true)
            : null,
    ];
};
